import { useState, useRef } from "react";
import toast, { Toaster } from 'react-hot-toast'
import "../styles/EditReminder.css";
import { Editor } from "@tinymce/tinymce-react";

const EditReminder = ({ endEdit, toggleEditor, reminder }) => {
  const editorRef = useRef(null);
  const [title, setTitle] = useState(reminder.title);
  const initialText = reminder.text;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editorRef.current && title !== '' && editorRef.current.getContent() !== '') {
      const editReminder = {
        id: reminder.id,
        title: title,
        text: editorRef.current.getContent(),
        complete: reminder.complete,
        date: reminder.date,
      };
      endEdit(reminder.id, editReminder);
    } else {
      toast.error("Fields cannot be empty")
    }
  };

  return (
    <div>
      <Toaster
        toastOptions={{
          style: {
            background: "#ff9999",
          },
        }}
        containerStyle={{
          top: 10,
        }}
      />
      <div className="add-heading">Edit Reminder</div>
      <form onSubmit={handleSubmit}>
        <input
          className="title-input"
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Editor
          apiKey="02ks8gbqqyravwwfh6ltwkow7zmhjkabje3t97oroictp6wu"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={initialText}
          init={{
            height: 400,
            skin: "outside",
            menubar: false,
            branding: false,
            resize: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <div className="item-controls">
          <button className="form-button submit" type="submit">
            Save Changes
          </button>
          <button
            className="form-button close"
            type="button"
            onClick={toggleEditor}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditReminder;
