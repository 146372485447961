import { useState, useEffect } from "react";
import ReminderList from "./components/ReminderList";
import ReminderEditor from "./components/ReminderEditor";
import ReminderDisplay from "./components/ReminderDisplay";
import EditReminder from "./components/EditReminder";
import ProfileSettings from "./components/ProfileSettings";


// Auth
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import provider from './auth';

// Firebase stuff
import {
  collection,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";
import db from "./firebase";

// import { getAnalytics } from "firebase/analytics";

import { IoMdAddCircle } from "react-icons/io";
import "./styles/App.css";

function App() {
  const [reminders, setReminders] = useState([{}]);
  const [showReminder, setShowReminder] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [editReminderData, setEditReminderData] = useState(0);
  const [selectedReminder, setSelectedReminder] = useState(0);
  const [userName, setUserName] = useState("null");
  const [emailName, setEmailName] = useState("reminder")
  const [signedIn, setSignedIn] = useState(false)
  // setEmailName("reminders")
  
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, emailName), orderBy("date")),
        (snapshot) => setReminders(snapshot.docs.map((doc) => doc.data()))
      ),
    [emailName],
  );

  // const auth = getAuth();

  const toggleComplete = (id) => {
    let changeIndex = reminders.findIndex((item) => item.id === id);
    reminders[changeIndex].complete = !reminders[changeIndex].complete;
    setDoc(doc(db, emailName, id), reminders[changeIndex]);
    setReminders([...reminders]);
  };

  const deleteReminder = async (id) => {
    await deleteDoc(doc(db, emailName, id))
    if (selectedReminder === id) {
    setSelectedReminder(reminders[0].id);
    }
  };

  const addReminder = async (newItem) => {
    setShowReminder(false);
    await setDoc(doc(db, emailName, newItem.id), newItem);
  };

  const toggleForm = () => {
    setShowReminder(!showReminder);
  };

  const editReminder = (id) => {
    let changeIndex = reminders.findIndex((item) => item.id === id);
    setEditReminderData(reminders[changeIndex]);
    setShowEditor(true);
  };

  const toggleEditor = () => {
    setShowEditor(false);
    setEditReminderData(0);
  };

  const endEdit = async (id, edited) => {
    await setDoc(doc(db, emailName, id), edited);
    setEditReminderData(0);
    setShowEditor(false);
  };

  const selectReminder = (id) => {
    setSelectedReminder(id);
  };

  const updateData = (newUser, newEmail, status) => {
    setEmailName(newEmail)
    setUserName(newUser)
    setSignedIn(status)
  }

  return (
    <main className="app">
      <div className="container-left">
        <div className="list-controls">
          <div className="list-heading">Reminders</div>
          <span className="toggle-form" onClick={toggleForm}>
            <IoMdAddCircle />
          </span>
        </div>
        <ReminderList
          reminders={reminders}
          toggleComplete={toggleComplete}
          deleteReminder={deleteReminder}
          editReminder={editReminder}
          selectedReminder={selectedReminder}
          selectReminder={selectReminder}
        />
        {/* <div className="profile-container"> */}
        <ProfileSettings updateData={updateData} userName={userName} signedIn={signedIn} />
        {/* </div> */}
      </div>
      <div className="container-right">
        {signedIn ? (showReminder ? (
          <ReminderEditor addReminder={addReminder} toggleForm={toggleForm} />
        ) : showEditor ? (
          <EditReminder
            endEdit={endEdit}
            toggleEditor={toggleEditor}
            reminder={editReminderData}
          />
        ) : (
          <ReminderDisplay
            reminders={reminders}
            selectedReminder={selectedReminder}
          />
        )) : <div className="sign-in-message">Please sign in with Google to use this application</div>}
        {/* <p dangerouslySetInnerHTML={{__html: text}} /> */}
      </div>
    </main>
  );
}

export default App;
