import "../styles/ReminderDisplay.css";

const ReminderDisplay = ({ reminders, selectedReminder }) => {
  // if (selectedReminder === 0) {
  //   selectedReminder = reminders[0].id;
  // }
  let reminder;
  if (reminders[0] === undefined) {
    reminder = [
      {
        id: '0',
        title: 'No reminders',
        text: ''
        
      }
    ];
  } else {
    reminder = reminders.filter((item) => item.id === selectedReminder);
  }
  try {
    // eslint-disable-next-line
    let errorTest = reminder[0].title;
  } catch (err) {
    reminder = reminders.filter((item) => item.id === reminders[0].id);
  }

  return (
    <div>
      <div className="display-title">{reminder[0].title}</div>
      <div
        className="display-text"
        dangerouslySetInnerHTML={{ __html: reminder[0].text }}
      />
    </div>
  );
};

export default ReminderDisplay;
