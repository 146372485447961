import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDRnxmdumZgOj-ET4pGnXy3Jo16HLDKCsY",
  authDomain: "minitodos.app",
  projectId: "react-reminders-2",
  storageBucket: "react-reminders-2.appspot.com",
  messagingSenderId: "497641258442",
  appId: "1:497641258442:web:7f64fda6425adee602e333",
  measurementId: "G-805HN52VEM",
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
export default db;
