import { MdDelete } from "react-icons/md";
import { AiFillCheckCircle, AiFillEdit } from "react-icons/ai";
import "../styles/ReminderItem.css";

const ReminderItem = ({
  reminder,
  toggleComplete,
  deleteReminder,
  editReminder,
  selectedReminder,
  selectReminder,
}) => {
  let selected = false;

  if (reminder.id === selectedReminder) {
    selected = true;
  }

  return (
    <div
      className={"item-card " + (selected ? "selected" : "unselected")}>
      <div className="item-controls" >
        <span className="item-name" onClick={() => selectReminder(reminder.id)}>{reminder.title}</span>
        <span
          className="complete-icon"
          onClick={() => toggleComplete(reminder.id)}
        >
          <AiFillCheckCircle />
        </span>
        <span className="edit-icon" onClick={() => editReminder(reminder.id)}>
          <AiFillEdit />
        </span>
        <span
          className="delete-icon"
          onClick={() => deleteReminder(reminder.id)}
        >
          <MdDelete />
        </span>
      </div>
    </div>
  );
};

export default ReminderItem;
