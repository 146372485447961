import ReminderItem from "./ReminderItem";
import "../styles/ReminderList.css";

const ReminderList = ({
  reminders,
  toggleComplete,
  deleteReminder,
  editReminder,
  selectedReminder,
  selectReminder,
}) => {
  const completeReminders = reminders.filter((item) => item.complete);
  const notComplete = reminders.filter((item) => item.complete === false);

  if (reminders.length === 1) {
    selectedReminder = reminders[0].id
  } else if (reminders.length === 0) {
    selectedReminder = 0
  }

  // if (selectedReminder === 0) {
  //   selectedReminder = reminders[0].id
  // }

  if (reminders.length !== 0 && reminders.filter((item) => item.id === selectedReminder).length === 0) {
    selectedReminder = reminders[0].id
  }


  return (
    <div className="list-container">
      <div className="hide-scrollbar">
        <div className="uncomplete">
          <div className="heading">To Do</div>
          {notComplete.length
            ? notComplete.map((item) => (
                <ReminderItem
                  reminder={item}
                  key={item.id}
                  toggleComplete={toggleComplete}
                  deleteReminder={deleteReminder}
                  editReminder={editReminder}
                  selectedReminder={selectedReminder}
                  selectReminder={selectReminder}
                />
              ))
            : "All caught up"}
        </div>
        <div className="complete">
          <div className="heading">Complete</div>
          {completeReminders.length
            ? completeReminders.map((item) => (
                <ReminderItem
                  reminder={item}
                  key={item.id}
                  toggleComplete={toggleComplete}
                  deleteReminder={deleteReminder}
                  editReminder={editReminder}
                  selectedReminder={selectedReminder}
                  selectReminder={selectReminder}
                />
              ))
            : "No complete items"}
        </div>
      </div>
    </div>
  );
};

export default ReminderList;
