import { useState } from "react";
import { IoMdSettings } from "react-icons/io";
import "../styles/ProfileSettings.css";

import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  // GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import provider from "../auth";

const ProfileSettings = ({ updateData, userName, signedIn }) => {
  const [userPfp, setUserPfp] = useState("../logo256.png");
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      updateData(user.displayName, user.email, true);
      setUserPfp(user.photoURL);
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  const login = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        // const user = result.user;
        // const email = result.email;
        // ...
        // console.log(auth.user.displayName)
        updateData(result.user.displayName, result.user.email, true);
        setUserPfp(result.user.photoURL);
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const signOutHandler = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        updateData("null", "reminders", false);
        setUserPfp("../logo256.png");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div className="profile-container">
      <div className="account">
        <div className="account-icon">
          <img className="pfp" src={userPfp} alt="pfp" />
        </div>
        {signedIn && <span className="user-name">{userName}</span>}
        {!signedIn && (
          <span className="login-out" onClick={login}>
            Log in
          </span>
        )}
      </div>
      <div className="settings">
        {signedIn && (
          <span className="sign-out" onClick={signOutHandler}>
            Sign out
          </span>
        )}
        <span className="settings-icon">
          <IoMdSettings />
        </span>
      </div>
    </div>
  );
};

export default ProfileSettings;
